import { useState, useEffect } from "react";
import { Box, Button, Stack, Switch } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";

import ResultList from "./ResultList.js";
import ModalWrapper from "../layout/ModalWrapper";

export default function ResultsGraph({ allCards, totalCardCount, results }) {
   return (
      <Stack spacing={3} sx={{ mt: 4 }}>
         <Stack alignItems='center' spacing={1}>
            <Box sx={{ color: "#ccc" }}>
               Right ({results.right.length} / {totalCardCount})
            </Box>
            <Box sx={{ width: 300, border: "1px solid #333" }}>
               <Box
                  sx={{
                     height: 20,
                     width: (results.right.length / totalCardCount) * 100 + "%",
                     backgroundColor: "green",
                     py: 1,
                     textAlign: "right",
                  }}
               >
                  <Box sx={{ px: 1 }}>
                     {parseInt((results.right.length / totalCardCount) * 100) +
                        "%"}
                  </Box>
               </Box>
            </Box>
         </Stack>

         <Stack alignItems='center' spacing={1}>
            <Box sx={{ color: "#ccc" }}>
               Wrong ({results.wrong.length} / {totalCardCount})
            </Box>
            <Box
               sx={{
                  width: 300,
                  border: "1px solid #333",
               }}
            >
               <Box
                  sx={{
                     height: 20,
                     width: (results.wrong.length / totalCardCount) * 100 + "%",
                     backgroundColor: "red",
                     py: 1,
                     textAlign: "right",
                  }}
               >
                  <Box sx={{ px: 1 }}>
                     {parseInt((results.wrong.length / totalCardCount) * 100) +
                        "%"}
                  </Box>
               </Box>
            </Box>
         </Stack>

         <ModalWrapper
            label='View Cards You Got Right'
            variant='outlined'
            fullWidth
         >
            <ResultList
               right={true}
               allCards={allCards}
               cardIDs={results.right}
            />
         </ModalWrapper>

         <ModalWrapper
            label='View Cards You Got Wrong'
            variant='outlined'
            fullWidth
         >
            <ResultList
               right={false}
               allCards={allCards}
               cardIDs={results.wrong}
            />
         </ModalWrapper>
      </Stack>
   );
}
