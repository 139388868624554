import { useState, useEffect } from "react";
import {
   Box,
   Button,
   Grid,
   Stack,
   TextField,
   Switch,
   Typography,
} from "@mui/material";

import ModalWrapper from "./layout/ModalWrapper";
import AllCards from "./components/AllCards";
import FlashCard from "./components/FlashCard";
import FlashCardArt from "./components/FlashCardArt";
import ScaleToWidth from "./layout/ScaleToWidth.js";
import SelectSets from "./components/SelectSets.js";
import ResultsGraph from "./components/ResultsGraph.js";

import _ from "lodash";
import axios from "axios";

const filterArray = [
   { name: "draftBoostersOnly", title: "Draft Booster Cards Only" },
   { name: "includeBasicLands", title: "Basic Lands" },
   { name: "includeLands", title: "Lands" },
   { name: "includeEnchantment", title: "Enchantment" },
   { name: "includeCreature", title: "Creature" },
   { name: "includeArtifact", title: "Artifact" },
   { name: "includeSorcery", title: "Sorcery" },
   { name: "includePlaneswalkers", title: "Planeswalkers" },
   { name: "includeInstant", title: "Instants" },
];

function App() {
   const [allCards, setAllCards] = useState([]);
   const [cards, setCards] = useState([]);
   const [card, showCard] = useState();

   const [results, setResults] = useState({ right: [], wrong: [] });

   const [filters, setFilters] = useState({
      draftBoostersOnly: true,
      includeBasicLands: false,
      includeLands: true,
      includeEnchantment: true,
      includeCreature: true,
      includeArtifact: true,
      includeSorcery: true,
      includePlaneswalkers: true,
      includeInstant: true,
   });

   const [showSets, setShowSets] = useState();
   const [allSets, setAllSets] = useState([]);
   const [cardsToPull, setCardsToPull] = useState([]);

   const [artMode, setArtMode] = useState(false);
   const [complete, setComplete] = useState(false);

   const handleSetsUpdate = (sets) => {
      setCardsToPull(sets);
   };

   function getCards(set, nextPage = "") {
      axios
         .get(
            nextPage
               ? nextPage
               : "https://api.scryfall.com/cards/search?order=name&unique=prints&q=set%3A" +
                    set
         )
         .then((data) => {
            setAllCards((prevState) => {
               return [...prevState, ...data.data.data];
            });

            setCards((prevState) => {
               return [...prevState, ...data.data.data];
            });

            if (data.data.has_more) {
               setTimeout(function () {
                  getCards(set, data.data.next_page);
               }, 100);
            } else {
               setAllCards((prevState) => {
                  let newState = [...prevState];

                  newState = _.orderBy(
                     newState,
                     ["set", "name"],
                     ["asc", "asc"]
                  );

                  if (!filters.includeLands) {
                     newState = _.filter(newState, function (card) {
                        return !card.type_line.includes("Land");
                     });
                  }

                  if (!filters.includeEnchantment) {
                     newState = _.filter(newState, function (card) {
                        return !card.type_line.includes("Enchantment");
                     });
                  }

                  if (!filters.includeCreature) {
                     newState = _.filter(newState, function (card) {
                        return !card.type_line.includes("Creature");
                     });
                  }

                  if (!filters.includeArtifact) {
                     newState = _.filter(newState, function (card) {
                        return !card.type_line.includes("Artifact");
                     });
                  }

                  if (!filters.includeSorcery) {
                     newState = _.filter(newState, function (card) {
                        return !card.type_line.includes("Sorcery");
                     });
                  }

                  if (!filters.includePlaneswalkers) {
                     newState = _.filter(newState, function (card) {
                        return !card.type_line.includes("Planeswalker");
                     });
                  }

                  if (!filters.includeInstant) {
                     newState = _.filter(newState, function (card) {
                        return !card.type_line.includes("Instant");
                     });
                  }

                  if (filters.draftBoostersOnly) {
                     newState = _.filter(newState, function (card) {
                        return card.booster === true;
                     });
                  }

                  if (!filters.includeBasicLands) {
                     newState = _.filter(newState, function (card) {
                        return !card.type_line.includes("Basic Land");
                     });
                  }

                  newState.length == 0 &&
                     alert(
                        "No cards found.  Try turning off Draft Boost Cards Only."
                     );

                  return newState;
               });

               setCards((prevState) => {
                  let newState = [...prevState];

                  newState = _.orderBy(
                     newState,
                     ["set", "name"],
                     ["asc", "asc"]
                  );

                  if (!filters.includeLands) {
                     newState = _.filter(newState, function (card) {
                        return !card.type_line.includes("Land");
                     });
                  }

                  if (!filters.includeEnchantment) {
                     newState = _.filter(newState, function (card) {
                        return !card.type_line.includes("Enchantment");
                     });
                  }

                  if (!filters.includeCreature) {
                     newState = _.filter(newState, function (card) {
                        return !card.type_line.includes("Creature");
                     });
                  }

                  if (!filters.includeArtifact) {
                     newState = _.filter(newState, function (card) {
                        return !card.type_line.includes("Artifact");
                     });
                  }

                  if (!filters.includeSorcery) {
                     newState = _.filter(newState, function (card) {
                        return !card.type_line.includes("Sorcery");
                     });
                  }

                  if (!filters.includePlaneswalkers) {
                     newState = _.filter(newState, function (card) {
                        return !card.type_line.includes("Planeswalker");
                     });
                  }

                  if (!filters.includeInstant) {
                     newState = _.filter(newState, function (card) {
                        return !card.type_line.includes("Instant");
                     });
                  }

                  if (filters.draftBoostersOnly) {
                     newState = _.filter(newState, function (card) {
                        return card.booster === true;
                     });
                  }

                  if (!filters.includeBasicLands) {
                     newState = _.filter(newState, function (card) {
                        return !card.type_line.includes("Basic Land");
                     });
                  }

                  return newState;
               });
            }
         })
         .catch((error) => console.log(error));
   }

   useEffect(() => {
      axios
         .get("https://api.scryfall.com/sets")
         .then((data) => {
            setAllSets(data.data.data);
         })
         .catch((error) => console.log(error));
   }, []);

   useEffect(() => {
      if (showSets) {
         showSets.forEach((set, i) => {
            setTimeout(function () {
               axios
                  .get("https://api.scryfall.com/sets/" + set)
                  .then((data) => {
                     getCards(set);
                  })
                  .catch((error) => console.log(error));
            }, 100 * i);
         });
      }
   }, [showSets]);

   useEffect(() => {
      // console.log(allSets);
   }, [allSets]);

   useEffect(() => {
      // console.log(results);
   }, [results]);

   useEffect(() => {
      // console.log(cards);
   }, [cards]);

   useEffect(() => {
      console.log(allCards);
   }, [allCards]);

   const reset = () => {
      setCards([]);
      setAllCards([]);
      setShowSets();
      showCard();
      setResults({ right: [], wrong: [] });
   };

   const getSets = () => {
      setCards([]);
      setAllCards([]);
      setShowSets();
      setResults({ right: [], wrong: [] });
      setTimeout(function () {
         setShowSets(cardsToPull);
      }, 100);
   };

   const gotItRight = (id) => {
      setCards((prevState) => {
         return _.filter(prevState, function (o) {
            return o.id != id;
         });
      });
      showRandomCard(id);

      setResults((prevState) => {
         let newState = { ...prevState };
         newState.right.indexOf(id) === -1 && newState.right.push(id);

         newState.wrong = _.filter(newState.wrong, function (o) {
            return o != id;
         });

         return newState;
      });
   };

   const gotItWrong = (id) => {
      showRandomCard(id);

      setResults((prevState) => {
         let newState = { ...prevState };
         newState.wrong.indexOf(id) === -1 && newState.wrong.push(id);
         return newState;
      });
   };

   const showRandomCard = (id) => {
      const randomCard =
         cards.length == 1
            ? _.sample(cards)
            : _.sample(
                 _.filter(cards, function (o) {
                    return o.id != id;
                 })
              );
      if (cards.length >= 1) {
         showCard(randomCard);
      } else {
         setComplete(true);
      }
   };

   const handleFilterChange = (e) => {
      setCards([]);
      setAllCards([]);
      setShowSets();
      showCard();
      setResults({ right: [], wrong: [] });
      setFilters((prevState) => {
         let newState = { ...prevState };
         newState[e.target.name] = e.target.checked;
         return newState;
      });
   };

   return (
      <Box sx={{ p: 4 }}>
         <h4>Evoke Flashcards</h4>

         <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            spacing={2}
         >
            <Grid item xs={12}>
               {cards.length == 0 && (
                  <ModalWrapper label='Setup Quiz'>
                     <SelectSets
                        allSets={allSets.map((set) => {
                           return { ...set, id: set.code };
                        })}
                        currentlyActive={cardsToPull}
                        callback={handleSetsUpdate}
                     />
                  </ModalWrapper>
               )}
               {cards.length > 0 && (
                  <Button variant='contained' color='error' onClick={reset}>
                     RESET QUIZ
                  </Button>
               )}
            </Grid>

            <Grid item xs={12} sm={3}>
               {cardsToPull.length > 0 && cards.length == 0 && (
                  <Button variant='contained' color='warning' onClick={getSets}>
                     Load Cards
                  </Button>
               )}

               {cards.length > 0 && (
                  <ModalWrapper
                     label='View Loaded Cards'
                     variant='outlined'
                     fullWidth
                  >
                     <AllCards
                        sets={_.filter(allSets, function (set) {
                           return cardsToPull.includes(set.code);
                        })}
                        cards={cards}
                     />
                  </ModalWrapper>
               )}
            </Grid>

            <Grid item xs={12} sm={3}>
               {cards.length > 0 && (
                  <Button
                     variant={artMode ? "contained" : "outlined"}
                     color={artMode ? "error" : "warning"}
                     onClick={() => setArtMode(!artMode)}
                     fullWidth
                  >
                     Advanced Mode (Art Only)
                  </Button>
               )}
            </Grid>
         </Grid>

         <Stack
            direction='row'
            justifyContent='flex-start'
            alignItems='flex-start'
         >
            {cards.length < 1 && cardsToPull.length > 0 && (
               <Box sx={{ minWidth: 300, mt: 4 }}>
                  <Typography variant='h6'>Selected Sets</Typography>

                  {_.filter(allSets, function (set) {
                     return cardsToPull.includes(set.code);
                  }).map((set) => (
                     <Box>{set.name}</Box>
                  ))}

                  <Box sx={{ mt: 4 }}>
                     {filterArray.map((filter) => (
                        <Stack
                           direction='row'
                           spacing={2}
                           alignItems='center'
                           sx={{
                              opacity:
                                 !filters["includeLands"] &&
                                 filter.name == "includeBasicLands"
                                    ? 0.5
                                    : 1,
                           }}
                        >
                           <Switch
                              color='warning'
                              disabled={
                                 !filters["includeLands"] &&
                                 filter.name == "includeBasicLands"
                                    ? true
                                    : false
                              }
                              checked={filters[filter.name]}
                              onChange={handleFilterChange}
                              name={filter.name}
                           />
                           <b>{filter.title}</b>
                        </Stack>
                     ))}
                  </Box>
               </Box>
            )}
            <Stack
               alignItems='center'
               sx={{ my: 4, width: "100%" }}
               spacing={2}
            >
               {cards.length > 0 && !card && (
                  <Stack alignItems='center'>
                     <Button
                        size='large'
                        variant='contained'
                        color='error'
                        sx={{ fontSize: 36, mb: 4 }}
                        onClick={showRandomCard}
                     >
                        Start Quiz
                     </Button>
                  </Stack>
               )}

               {complete && cards.length == 0 && (
                  <Typography variant='h4'>Flashcard Quiz Complete</Typography>
               )}

               {cards.length > 0 && card && (
                  <>
                     <ScaleToWidth contentWidth={400} contentHeight={557}>
                        {(parent) => (
                           <>
                              {!artMode && (
                                 <FlashCard
                                    card={card}
                                    showRandomCard={showRandomCard}
                                 />
                              )}
                              {artMode && (
                                 <FlashCardArt
                                    card={card}
                                    showRandomCard={showRandomCard}
                                 />
                              )}
                           </>
                        )}
                     </ScaleToWidth>

                     <Box>Number of cards remaining: {cards.length}</Box>

                     <Stack direction='row' spacing={2} sx={{ pb: 4 }}>
                        <Button
                           onClick={() => gotItRight(card.id)}
                           variant='contained'
                           color='success'
                        >
                           Got It Right
                        </Button>

                        <Button
                           onClick={() => gotItWrong(card.id)}
                           variant='contained'
                           color='error'
                        >
                           Got It Wrong
                        </Button>
                     </Stack>

                     {results.wrong.length + results.right.length > 0 && (
                        <ResultsGraph
                           allCards={allCards}
                           results={results}
                           totalCardCount={allCards.length}
                        />
                     )}
                  </>
               )}
            </Stack>
         </Stack>
      </Box>
   );
}

export default App;
