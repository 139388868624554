import { useState, useEffect } from "react";
import { Box, Button, Stack } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";

import ImagePreloader from "../layout/ImagePreloader";
import HintMana from "../layout/HintMana";

export default function FlashCard({ card }) {
   const [visible, setVisible] = useState(false);

   useEffect(() => {
      console.log(card);
      setVisible(false);
   }, [card]);

   return (
      <Stack direction='row' spacing={2} alignItems='center'>
         <Box
            as={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            sx={{ width: 400, height: 557, position: "relative" }}
         >
            <AnimatePresence>
               {!visible && (
                  <>
                     <Stack
                        as={motion.div}
                        initial={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        spacing={1}
                        sx={{
                           backgroundColor: "#111",
                           width: "100%",
                           height: "100%",
                           borderRadius: 4,
                           position: "absolute",
                        }}
                        alignItems='center'
                        justifyContent='center'
                     >
                        <Box sx={{ height: 50, width: "100%", pr: 4 }}>
                           <HintMana mana={card.mana_cost} key={card.id} />
                        </Box>

                        <Box
                           sx={{
                              width: "100%",
                              height: "60%",
                              textAlign: "center",
                           }}
                        >
                           {card.image_uris && card.image_uris.art_crop && (
                              <Stack
                                 sx={{
                                    backgroundColor: "#111",
                                    width: "100%",
                                    height: "100%",
                                    overflow: "hidden",
                                    maxWidth: 400,
                                    maxHeight: 330,
                                 }}
                                 alignItems='center'
                                 justifyContent='center'
                              >
                                 <ImagePreloader
                                    imageUrl={card.image_uris.art_crop}
                                 />
                              </Stack>
                           )}
                           {!card.image_uris && (
                              <Box
                                 sx={{
                                    position: "absolute",
                                    top: "40%",
                                    width: "100%",
                                    textAlign: "center",
                                    color: "#fff",
                                 }}
                              >
                                 <Box sx={{ p: 4 }}>
                                    No image available for {card.name} (
                                    {card.set})
                                 </Box>
                              </Box>
                           )}
                        </Box>

                        <Stack
                           direction='row'
                           sx={{
                              color: "#666",
                              fontSize: 10,
                              width: "100%",
                              pb: 2,
                           }}
                           justifyContent='space-between'
                        >
                           <Box
                              sx={{
                                 px: 2,
                              }}
                           >
                              {card.artist}
                           </Box>
                           <Box
                              sx={{
                                 px: 2,
                              }}
                           >
                              ™ & © {card.released_at.split("-")[0]} Wizards of
                              the Coast
                           </Box>
                        </Stack>
                        <Box sx={{ px: 2, width: "90%" }}>
                           <Button
                              variant='contained'
                              size='large'
                              onClick={() => setVisible(true)}
                              sx={{
                                 height: 80,
                                 backgroundColor: "rgba(0,0,0,.5)",
                                 border: "1px solid rgba(255,0,0,.5)",
                              }}
                              fullWidth
                           >
                              Show Card
                           </Button>
                        </Box>
                     </Stack>
                  </>
               )}
            </AnimatePresence>

            <AnimatePresence>
               {visible && (
                  <>
                     {card.image_uris && card.image_uris.normal && (
                        <Stack
                           sx={{
                              backgroundColor: "#111",
                              borderRadius: 5,
                              width: "100%",
                              height: "100%",
                              overflow: "hidden",
                           }}
                           alignItems='center'
                           justifyContent='flex-start'
                        >
                           <ImagePreloader imageUrl={card.image_uris.normal} />
                        </Stack>
                     )}

                     {!card.image_uris && (
                        <Box
                           sx={{
                              backgroundColor: "#111",
                              color: "#fff",
                              backgroundSize: "cover",
                              width: "100%",
                              height: "100%",
                              borderRadius: 4,
                              position: "relative",
                           }}
                        >
                           <Box
                              sx={{
                                 position: "absolute",
                                 top: "40%",
                                 width: "100%",
                                 textAlign: "center",
                              }}
                           >
                              <Box sx={{ p: 4 }}>
                                 No image available for {card.name} ({card.set})
                              </Box>
                           </Box>
                        </Box>
                     )}
                  </>
               )}
            </AnimatePresence>
         </Box>
      </Stack>
   );
}
