import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

import _ from "lodash";

function TabPanel(props) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role='tabpanel'
         hidden={value !== index}
         id={`vertical-tabpanel-${index}`}
         aria-labelledby={`vertical-tab-${index}`}
         {...other}
         style={{ width: "100%" }}
      >
         {value === index && (
            <Box
               sx={{
                  p: 0,
                  overflow: "scroll",
                  height: "70vh",
                  width: "100%",
                  color: "#666",
               }}
            >
               {children}
            </Box>
         )}
      </div>
   );
}

TabPanel.propTypes = {
   children: PropTypes.node,
   index: PropTypes.number.isRequired,
   value: PropTypes.number.isRequired,
};

function a11yProps(index) {
   return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
   };
}

export default function AllCards({ cards, sets }) {
   const [value, setValue] = React.useState(0);

   const handleChange = (event, newValue) => {
      setValue(newValue);
   };

   return (
      <>
         <Typography variant='h6' sx={{ mx: 2, my: 2 }}>
            Selected Sets
         </Typography>
         {cards.length > 0 && sets && (
            <Box sx={{ overflowY: "scroll", height: "70vh" }}>
               <Tabs
                  orientation='vertical'
                  variant='scrollable'
                  value={value}
                  onChange={handleChange}
                  sx={{
                     width: "100%",
                     backgroundColor: "rgba(0,0,0,.2)",
                     borderRadius: 4,
                     ".MuiTab-root": { alignItems: "start" },
                     ".MuiTab-textColorPrimary": { color: "#ccc" },
                  }}
               >
                  {sets.map((set, i) => (
                     <Tab
                        sx={{
                           textAlign: "left",
                        }}
                        label={set.name}
                        {...a11yProps(i)}
                     />
                  ))}
               </Tabs>

               {sets.map((set, i) => (
                  <TabPanel value={value} index={i}>
                     <Stack spacing={0} sx={{ mt: 2, width: "100%" }}>
                        {_.filter(cards, { set: set.code }).length === 0 && (
                           <Box sx={{ backgroundColor: "#fff", p: 4 }}>
                              <p>
                                 <b>No cards currently showing for this Set.</b>
                              </p>
                              <p>
                                 This is because the selected filters are hiding
                                 all cards in this Set, or because there is no
                                 data available in Scryfall.
                              </p>
                           </Box>
                        )}
                        {_.filter(cards, { set: set.code }).map((card, c) => (
                           <Stack
                              direction='row'
                              spacing={2}
                              alignItems='center'
                              sx={{
                                 backgroundColor: c % 2 ? "#fff" : "#efefef",
                                 borderTop: "1px solid #ccc",
                                 py: 2,
                                 width: "100%",
                              }}
                           >
                              <Box sx={{ width: 150, pl: 4 }}>
                                 {card.image_uris && (
                                    <img
                                       src={card.image_uris.small}
                                       width='100%'
                                       styles='display: block;'
                                    />
                                 )}

                                 {!card.image_uris && (
                                    <Box
                                       sx={{
                                          backgroundColor: "red",
                                          color: "white",
                                          height: 120,
                                          textAlign: "center",
                                          py: 4,
                                       }}
                                    >
                                       No Card Image Available
                                    </Box>
                                 )}
                              </Box>
                              <Box sx={{ width: 400, textAlign: "center" }}>
                                 <b>{card.name}</b>
                                 <br />
                                 {card.set_name}
                                 <br />
                                 <i style={{ opacity: 0.5 }}>
                                    {card.type_line}
                                 </i>
                                 {/* <Button onClick={() => console.log(card)}>
                                    Console Log Card
                                 </Button> */}
                              </Box>
                           </Stack>
                        ))}
                     </Stack>
                  </TabPanel>
               ))}
            </Box>
         )}
      </>
   );
}
