import React from "react";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

export default function ManaIcons({ iconWidth, mana, sx }) {
   const formatMana = (manaString) => {
      if (manaString != "null" && manaString != "") {
         return manaString
            .split("//")[0]
            .replace(" ", "")
            .replace(/[//]/g, "")
            .slice(1, -1)
            .split("}{");
      } else {
         return [];
      }
   };

   return (
      <>
         {mana && (
            <Stack direction='row' spacing={0.5} sx={{ ...sx }}>
               {formatMana(mana).map((icon, i) => (
                  <Box
                     sx={{
                        position: "relative",
                        width: iconWidth,
                        height: iconWidth,
                     }}
                     key={"manaIcon-" + i + "-" + icon}
                  >
                     <Box
                        sx={{
                           position: "absolute",
                           zIndex: 10,
                           width: "100%",
                           height: "100%",
                        }}
                        className={"mana-" + icon}
                     />
                     <Box
                        sx={{
                           position: "absolute",
                           zIndex: 1,
                           top: "7%",
                           left: "-3.5%",
                           width: "100%",
                           height: "100%",
                           backgroundColor: "#000",
                           borderRadius: 100,
                        }}
                     />
                  </Box>
               ))}
            </Stack>
         )}
      </>
   );
}
