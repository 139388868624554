import React, { useState } from "react";

import ManaIcons from "../layout/ManaIcons";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

export default function HintMana({ mana }) {
   const [show, setShow] = useState(false);

   useState(() => {
      console.log("loaded hint mana");
   }, []);

   return (
      <Stack
         direction='row'
         sx={{
            width: "100%",
            height: "100%",
         }}
         alignItems='center'
         justifyContent='flex-end'
      >
         {!show && (
            <Button
               onClick={() => setShow(true)}
               size='small'
               variant='outlined'
               color='error'
            >
               Show Mana
            </Button>
         )}

         {show && <ManaIcons iconWidth={20} mana={mana} />}
      </Stack>
   );
}
