import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

import _ from "lodash";

export default function ResultList({ right, allCards, cardIDs }) {
   return (
      <>
         <Typography variant='h5' sx={{ mx: 2, my: 2 }}>
            {right ? "Cards You Got Right" : "Cards You Got Wrong"}
         </Typography>

         <Stack
            spacing={2}
            alignItems='center'
            sx={{ overflow: "scroll", mt: 8, height: "65vh" }}
         >
            {_.filter(allCards, function (card) {
               return cardIDs.includes(card.id);
            }).map((card, i) => (
               <Stack
                  direction='row'
                  spacing={2}
                  alignItems='center'
                  sx={{
                     backgroundColor: i % 2 ? "none" : "rgba(0,0,0,.2)",
                     py: 2,
                     mt: 0,
                     width: "100%",
                  }}
               >
                  <Box sx={{ width: 150, pl: 4 }}>
                     {card.image_uris && (
                        <img
                           src={card.image_uris.small}
                           width='100%'
                           styles='display: block;'
                        />
                     )}

                     {!card.image_uris && (
                        <Box
                           sx={{
                              backgroundColor: "red",
                              color: "white",
                              height: 120,
                              textAlign: "center",
                              py: 4,
                           }}
                        >
                           No Card Image Available
                        </Box>
                     )}
                  </Box>
                  <Box sx={{ width: 400, textAlign: "center" }}>
                     <b>{card.name}</b>
                     <br />
                     {card.set_name}
                     <br />
                     <i style={{ opacity: 0.5 }}>{card.type_line}</i>
                     {/* <br />
                     <Button onClick={() => console.log(card)}>
                        Console Log Card
                     </Button> */}
                  </Box>
               </Stack>
            ))}
         </Stack>
      </>
   );
}
