import { useState, useEffect } from "react";
import { Box, Button, Stack } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";

import ImagePreloader from "../layout/ImagePreloader";
import HintMana from "../layout/HintMana";

export default function FlashCard({ card }) {
   const [visible, setVisible] = useState(false);

   useEffect(() => {
      setVisible(false);
   }, [card]);

   return (
      <Stack direction='row' spacing={2} alignItems='center'>
         <Box
            as={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            sx={{ width: 400, height: 557, position: "relative" }}
         >
            <AnimatePresence>
               {!visible && (
                  <Box
                     as={motion.div}
                     initial={{ opacity: 1 }}
                     exit={{ opacity: 0 }}
                     sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        zIndex: 1000,
                     }}
                  >
                     <Stack
                        direction='row'
                        sx={{
                           width: 360,
                           height: 42,
                           top: 18,
                           left: 20,
                           position: "absolute",
                           backgroundColor: "#111",
                           borderRadius: 2,
                        }}
                        alignItems='center'
                        justifyContent='flex-end'
                     >
                        <Box sx={{ height: 50, width: "100%", pr: 2 }}>
                           <HintMana mana={card.mana_cost} key={card.id} />
                        </Box>
                     </Stack>

                     <Stack
                        sx={{
                           width: 360,
                           height: 230,
                           top: 313,
                           left: 20,
                           position: "absolute",
                           backgroundColor: "#111",
                           borderRadius: 2,
                        }}
                        alignItems='center'
                        justifyContent='space-between'
                     >
                        <Box sx={{ px: 2, width: "90%" }}>
                           <Button
                              variant='contained'
                              size='large'
                              onClick={() => setVisible(true)}
                              sx={{
                                 mt: 2,
                                 height: 150,
                                 backgroundColor: "rgba(0,0,0,.5)",
                                 border: "1px solid rgba(255,0,0,.5)",
                              }}
                              fullWidth
                           >
                              Show Card
                           </Button>
                        </Box>

                        <Stack
                           direction='row'
                           sx={{
                              color: "#666",
                              fontSize: 10,
                              width: "100%",
                              pb: 1,
                           }}
                           justifyContent='space-between'
                        >
                           <Box
                              sx={{
                                 px: 2,
                              }}
                           >
                              {card.artist}
                           </Box>
                           <Box
                              sx={{
                                 px: 2,
                              }}
                           >
                              ™ & © {card.released_at.split("-")[0]} Wizards of
                              the Coast
                           </Box>
                        </Stack>
                     </Stack>
                  </Box>
               )}
            </AnimatePresence>

            {card.image_uris && (
               <Stack
                  sx={{
                     backgroundColor: "#111",
                     borderRadius: 5,
                     width: "100%",
                     height: "100%",
                     overflow: "hidden",
                  }}
                  alignItems='center'
                  justifyContent='flex-start'
               >
                  <ImagePreloader imageUrl={card.image_uris.normal} />
               </Stack>
            )}

            {!card.image_uris && (
               <Box
                  sx={{
                     backgroundColor: "#111",
                     color: "#fff",
                     backgroundSize: "cover",
                     width: "100%",
                     height: "100%",
                     borderRadius: 4,
                     position: "relative",
                  }}
               >
                  <Box
                     sx={{
                        position: "absolute",
                        top: "20%",
                        width: "100%",
                        textAlign: "center",
                     }}
                  >
                     <Box sx={{ p: 4 }}>
                        No image available for {card.name}
                        <p>
                           <b>{card.set_name}</b>
                        </p>
                     </Box>
                  </Box>
               </Box>
            )}
         </Box>
      </Stack>
   );
}
