import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: "80vw",
   height: "80vh",
   bgcolor: "#323744",
   border: "2px solid #000",
   borderRadius: 4,
   boxShadow: 24,
   p: 4,
   overflow: "hidden",
};

export default function ModalWrapper({ label, variant, fullWidth, children }) {
   const [open, setOpen] = React.useState(false);
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);

   return (
      <div>
         <Button
            onClick={handleOpen}
            variant={variant ? variant : "contained"}
            fullWidth={fullWidth}
         >
            {label}
         </Button>
         <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-title'
            aria-describedby='modal-description'
         >
            <Box sx={style}>
               <Button
                  variant='outlined'
                  sx={{ position: "absolute", top: 20, right: 30 }}
                  onClick={handleClose}
               >
                  Close
               </Button>
               <Typography id='modal-description' sx={{ mt: 2 }}>
                  {children}
               </Typography>
            </Box>
         </Modal>
      </div>
   );
}
