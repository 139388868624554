// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   background-color: rgb(31, 34, 42);
   color: #ccc;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
}

.MuiDataGrid-root
   .MuiDataGrid-columnHeaderCheckbox
   .MuiDataGrid-columnHeaderTitleContainer {
   display: none;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;GACG,SAAS;GACT;;kCAE+B;GAC/B,mCAAmC;GACnC,kCAAkC;GAClC,iCAAiC;GACjC,WAAW;AACd;;AAEA;GACG;eACY;AACf;;AAEA;;;GAGG,aAAa;AAChB","sourcesContent":["body {\n   margin: 0;\n   font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\",\n      \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\",\n      \"Helvetica Neue\", sans-serif;\n   -webkit-font-smoothing: antialiased;\n   -moz-osx-font-smoothing: grayscale;\n   background-color: rgb(31, 34, 42);\n   color: #ccc;\n}\n\ncode {\n   font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n      monospace;\n}\n\n.MuiDataGrid-root\n   .MuiDataGrid-columnHeaderCheckbox\n   .MuiDataGrid-columnHeaderTitleContainer {\n   display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
