import { useState, useEffect } from "react";
import { Box, Button, Stack, Switch } from "@mui/material";
import {
   DataGrid,
   GridToolbarFilterButton,
   GridColDef,
   GridValueGetterParams,
} from "@mui/x-data-grid";

import { motion, AnimatePresence } from "framer-motion";

const columns: GridColDef[] = [
   { field: "id", headerName: "ID", width: 50, visible: false },
   {
      field: "name",
      headerName: "Set",
      width: 300,
      minWidth: 150,
      maxWidth: 300,
   },
   {
      field: "code",
      headerName: "Set Code",
      width: 100,
   },
   {
      field: "released_at",
      headerName: "Release Date",
      width: 200,
   },
];

export default function SelectSets({ allSets, currentlyActive, callback }) {
   const handleChange = (setCode) => {
      callback(setCode);
   };

   return (
      <Box
         spacing={2}
         alignItems='center'
         sx={{ overflow: "scroll", mt: 8, height: "75vh" }}
      >
         <DataGrid
            sx={{ backgroundColor: "#ccc", pt: 2 }}
            rows={allSets}
            columns={columns}
            slots={{
               toolbar: GridToolbarFilterButton,
            }}
            initialState={{
               pagination: {
                  paginationModel: {
                     pageSize: 50,
                  },
               },
               columns: {
                  columnVisibilityModel: {
                     id: false,
                  },
               },
               filter: {
                  filterModel: {
                     items: [{ field: "name" }],
                  },
               },
            }}
            pageSizeOptions={[50]}
            checkboxSelection
            onRowSelectionModelChange={(newSelection) => {
               handleChange(newSelection);
            }}
            rowSelectionModel={currentlyActive}
         />

         {/* <Stack
            direction='row'
            alignItems='center'
            spacing={2}
            sx={{ fontWeight: "bold", color: "#999" }}
         >
            <Box sx={{ width: 100 }} />
            <Box sx={{ width: 30 }}></Box>
            <Box sx={{ width: 400 }}>Set Name</Box>
            <Box sx={{ width: 65, textAlign: "center" }}>Code</Box>
            <Box sx={{ width: 125, textAlign: "center" }}>Release Date</Box>
         </Stack>

         {allSets.slice(0, 100).map((set) => (
            <Stack
               direction='row'
               alignItems='center'
               spacing={2}
               sx={{ borderTop: "1px solid #ccc", pt: 2, px: 4 }}
            >
               <Box sx={{ width: 100 }}>
                  <Switch
                     color='warning'
                     defaultChecked={currentlyActive.includes(set.code)}
                     value={set.code}
                     onChange={handleChange}
                  />
               </Box>
               <Box sx={{ width: 30 }}>
                  <img src={set.icon_svg_uri} width='100%' />
               </Box>
               <Box sx={{ width: 400 }}>{set.name}</Box>
               <Box sx={{ width: 65, textAlign: "center" }}>{set.code}</Box>
               <Box sx={{ width: 125, textAlign: "center" }}>
                  {set.released_at}
               </Box>
            </Stack>
         ))} */}
      </Box>
   );
}
