import { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";

import Loading from "./loader.svg";

function preloadImage(src) {
   return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
         resolve(img);
      };
      img.onerror = img.onabort = function () {
         reject(src);
      };
      img.src = src;
   });
}

export default function ImagePreloader({ imageUrl }) {
   const [loaded, setLoaded] = useState(false);

   useEffect(() => {
      setLoaded(false);
      preloadImage(imageUrl)
         .then((response) => {
            setLoaded(true);
         })
         .catch((error) => {
            setLoaded(false);
         });
   }, [imageUrl]);

   return (
      <>
         {!loaded && (
            <motion.div
               key={"loader-" + imageUrl}
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
            >
               <img
                  as={motion.div}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  src={Loading}
                  width='70'
                  style={{ transform: "translateY(100px)" }}
               />
            </motion.div>
         )}

         {loaded && (
            <motion.div
               key={imageUrl}
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
            >
               <img
                  src={imageUrl}
                  style={{
                     width: "100%",
                  }}
               />
            </motion.div>
         )}
      </>
   );
}
